import React, { useEffect, useState } from "react";
import Header from "../../header/Header";
import Loader from "../../loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Col,
  Container,
  Row,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Footer from "../../footer/Footer";
import listingArr from "./BlogData.json"; // Import JSON file

const Blog = () => {
  const [loading, setloading] = useState(true);
  const [items, setItems] = useState(listingArr.slice(0, 5)); // Load first 5 items
  const [hasMore, setHasMore] = useState(false);
  const [limit, setlimit] = useState(5);
  const [offset, setoffset] = useState(0);

  useEffect(() => {
    setItems(listingArr.slice(offset, limit));
    if (listingArr.slice(offset, limit).length >= listingArr.length) {
      setHasMore(false); // No more data to load
    } else {
      setHasMore(true);
    }
    setTimeout(() => {
      setloading(false);
    }, 500);
  }, []);

  const fetchData = () => {
    debugger;
    if (items.length < listingArr.length) {
      setTimeout(() => {
        const newOffset = offset + limit; // Increase the offset
        const start = newOffset;
        const end = start + limit;

        // Slice the next batch of data
        const nextBatch = listingArr.slice(start, end);

        setItems((prevList) => [...prevList, ...nextBatch]); // Append new data to the list
      }, 500);
    } else {
      setHasMore(false); // No more data to load
    }
  };

  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="hero">
            <Container>
              <Row className="justify-content-center">
                <Col xl="21">
                  <h2 className="text-center mb-0">Elderly Life Blog</h2>
                  <p className="lead-xl text-center mt-4">
                    Read the latest blogs from Elderly Life on a range of topics
                    covering outgoings, warranties, todo's, gift cards &
                    vouchers and plans.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          <main className="main flex-grow-1">
            <section className="bg-primary-light">
              <Container className="p-md-0 pb-10rem">
                <InfiniteScroll
                  dataLength={items.length} //This is important field to render the next data
                  next={fetchData}
                  hasMore={hasMore}
                  loader={
                    <div
                      class="loader-main"
                      style={{ height: "auto", marginTop: "2.5rem" }}
                    >
                      <div class="loader"></div>
                    </div>
                  }
                  className="overflow-hidden"
                >
                  <Row className="row-cols-1 row-cols-md-2 row-cols-xl-3 gy-5 gx-6 pt-10 pt-md-11 pb-0">
                    {items &&
                      items.map((arrVal) => (
                        <Col>
                          <div className="bg-white rounded-3 p-3 h-100">
                            <a
                              className="link-dark text-decoration-none"
                              href={`/blog/${arrVal.postID}/${arrVal.title
                                .toLowerCase()
                                .replace(/ /g, "-")
                                .replace(/[^a-z0-9-]/g, "")}`}
                            >
                              <Image
                                src={arrVal.imageSrc}
                                className="img-fluid rounded-3 mb-3"
                                alt=""
                              />
                              <div className="p-3 text-dark text-primary">
                                <div
                                  className="d-flex align-items-center  mb-3 fs-16"
                                  style={{ color: "#003A6F" }}
                                >
                                  <p className="fw-black">
                                    {Array.isArray(arrVal.categories)
                                      ? arrVal.categories[0]
                                      : arrVal.categories}
                                  </p>
                                  {Array.isArray(arrVal.categories) && (
                                    <>
                                      <p className="mx-1"> &</p>
                                      <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                          <Tooltip
                                            id="button-tooltip"
                                            className="text-start"
                                          >
                                            {arrVal.categories
                                              .slice(1)
                                              .map((val) => (
                                                <div className="d-flex my-1">
                                                  {val}
                                                </div>
                                              ))}
                                          </Tooltip>
                                        }
                                      >
                                        <p className="fw-semibold text-decoration-underline cursor-pointer">
                                          {arrVal.categories.slice(1).length}{" "}
                                          others
                                        </p>
                                      </OverlayTrigger>
                                    </>
                                  )}
                                </div>
                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip
                                      id="button-tooltip"
                                      className="text-start"
                                    >
                                      {arrVal.title}
                                    </Tooltip>
                                  }
                                >
                                  <h3 className="h5 mb-3 text-truncate-2">
                                    {" "}
                                    {arrVal.title}
                                  </h3>
                                </OverlayTrigger>
                                <div
                                  style={{ color: "#473917" }}
                                  className="text-truncate-4"
                                >
                                  {arrVal.description}
                                </div>
                                <div
                                  className="mt-4 fs-14"
                                  style={{ color: "gray" }}
                                >
                                  {arrVal.timeToRead} to read
                                </div>
                              </div>
                            </a>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </InfiniteScroll>
              </Container>
            </section>
          </main>
          <Footer />
        </>
      )}
    </>
  );
};

export default Blog;
